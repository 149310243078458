import { mdiAccountOutline, mdiCogOutline, mdiDatabaseOutline, mdiDnsOutline, mdiPencilOutline } from '@mdi/js'

export default function useUIResolver() {
  const resolveUserRoleVariant = role => {
    if (role === 'Driver') return 'primary'
    if (role === 'Operator') return 'warning'
    if (role === 'App Admin') return 'success'
    if (role === 'Mechanic') return 'info'
    if (role === 'Engineer') return 'error'

    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'Driver') return mdiAccountOutline
    if (role === 'Operator') return mdiCogOutline
    if (role === 'App Admin') return mdiDatabaseOutline
    if (role === 'Mechanic') return mdiPencilOutline
    if (role === 'Engineer') return mdiDnsOutline

    return mdiAccountOutline
  }

  const resolveUserStatusVariant = status => {
    if (status === 1) return 'success'
    if (status === 0) return 'secondary'

    return 'primary'
  }

  const resolveStockRemainingStatus = item => {
    if (item.in_stock <= 5 && item.in_stock > 0) {
      return 'warning'
    }
    if (item.in_stock === 0) {
      return 'error'
    }

    return 'success'
  }

  const resolveAppointmentStatus = status => {
    if (status === 'Pending') {
      return 'warning'
    }
    if (status === 'Declined') {
      return 'error'
    }

    return 'success'
  }

  const resolveUnitCondition = condition => {
    if (condition === 'Under Maintenance') {
      return 'warning'
    }
    if (condition === 'Not running') {
      return 'error'
    }

    return 'success'
  }

  const resolveDate = date => {
    const sameYear = new Date().getFullYear() === new Date(date).getFullYear()
    let options = {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    }

    if (!sameYear) {
      options = { ...options, ...{ year: 'numeric' } }
    }

    return new Date(date).toLocaleDateString('en-us', options)
  }

  /**
   * HEADERS
   */

  const jobOrderTableHeaders = () => [
    {
      text: 'CONTROL #',
      value: 'control_number',
      align: 'start',
      width: 150,
    },
    {
      text: 'PLATE NO',
      value: 'plate_number',
      width: 130,
      sortable: false,
    },
    {
      text: 'VEHICLE',
      value: 'vehicle',
      width: 150,
      sortable: false,
    },
    {
      text: 'DRIVER',
      value: 'vehicle.driver.full_name',
      width: 100,
      sortable: false,
    },
    {
      text: 'DATE STARTED',
      value: 'start_date',
      width: 150,
    },
    {
      text: 'DATE COMPLETED',
      value: 'completion_date',
      width: 150,
    },
    {
      text: 'STATUS',
      value: 'status',
      width: 140,
    },
    {
      text: 'PRE-REPAIR',
      value: 'pre_repair',
      align: 'start',
      width: 60,
      sortable: false,
    },
    {
      text: 'ACTIONS',
      value: 'actions',
      sortable: false,
      align: 'center',
      width: 90,
    },
  ]

  const risHeaders = () => [
    {
      text: 'QTY',
      value: 'quantity',
      width: 60,
    },
    {
      text: 'DESCRIPTION',
      value: 'product.name',
      width: 400,
    },
    {
      text: 'UNIT PRICE',
      value: 'unit_price',
      width: 100,
    },
    {
      text: 'TOTAL',
      value: 'total',
      width: 120,
    },
  ]

  const materialUsedHeaders = () => [
    {
      text: 'QTY',
      value: 'quantity',
      width: 60,
    },
    {
      text: 'DESCRIPTION',
      value: 'description',
      width: 400,
    },
    {
      text: 'UNIT PRICE',
      value: 'unit_price',
      width: 100,
    },
    {
      text: 'TOTAL',
      value: 'total',
      width: 120,
    },
  ]

  const preRepairTableHeaders = () => [
    {
      text: '#ID',
      value: 'id',
      align: 'start',
      width: 50,
    },
    {
      text: '',
      value: 'actions_2',
      sortable: false,
      align: 'center',
      width: 90,
    },
    {
      text: 'PLATE NO',
      value: 'plate_number',
      width: 130,
    },
    {
      text: 'VEHICLE',
      value: 'vehicle',
      width: 300,
    },
    {
      text: 'DATE',
      value: 'issue_date',
      width: 150,
    },
    {
      text: 'COMPLETED',
      value: 'completion_date',
      width: 140,
    },

    {
      text: 'ACTIONS',
      value: 'actions',
      sortable: false,
      align: 'center',
      width: 50,
    },
  ]

  const wasteReportTableHeaders = () => [
    {
      text: 'JO #',
      value: 'id',
      align: 'start',
      width: 100,
    },
    {
      text: 'CONTROL #',
      value: 'control_number',
      width: 150,
    },
    {
      text: 'DATE',
      value: 'report_date',
      width: 80,
    },
    {
      text: 'DEPARTMENT',
      value: 'department_name',
      width: 150,
    },
    {
      text: 'STORAGE',
      value: 'storage',
      width: 150,
    },
    {
      text: 'TOTAL',
      value: 'total',
      width: 140,
    },
    {
      text: 'NO. OF ITEMS',
      value: 'material_counts',
      width: 100,
    },
    {
      text: 'ACTIONS',
      value: 'actions',
      sortable: false,
      align: 'center',
      width: 50,
    },
  ]

  const appointmentTableHeaders = () => [
    {
      text: 'PLATE NO',
      value: 'plate_number',
      width: 1 - 0,
    },
    {
      text: 'DATE',
      value: 'date',
      width: 180,
    },
    {
      text: 'DETAILS',
      value: 'description',
      width: 250,
    },

    {
      text: 'BOOKED BY',
      value: 'booked_by',
      width: 100,
    },
    {
      text: 'PHONE',
      value: 'phone',
      width: 80,
    },
    {
      text: 'STATUS',
      value: 'status',
      width: 100,
    },
    {
      text: 'ACTIONS',
      value: 'actions',
      sortable: false,
      align: 'center',
      width: 90,
    },
  ]
  const employeeTableHeaders = () => [
    { text: 'NAME', value: 'first_name', width: 250 },
    { text: 'EMAIL', value: 'email', width: 200 },
    { text: 'ROLE', value: 'roles' },
    {
      text: 'STATUS',
      value: 'is_active',
      width: 100,
      align: 'center',
    },
    {
      text: 'CAN LOGIN',
      value: 'can_login',
      width: 120,
      align: 'center',
    },
    {
      text: 'ACTIONS',
      value: 'actions',
      sortable: false,
      align: 'center',
      width: 90,
    },
  ]

  const vehicleTableHeaders = () => [
    {
      text: 'PLATE NO',
      value: 'plate_number',
      align: 'start',
      width: 150,
    },
    { text: 'DEPARTMENT', value: 'department_name', width: 140 },
    { text: 'DRIVER', value: 'driver.full_name', width: 120 },
    { text: 'MAKE', value: 'make.name', width: 150 },
    { text: 'MODEL', value: 'make_model.name' },
    { text: 'COLOR', value: 'color', width: 120 },
    {
      text: 'STATUS',
      value: 'status',
      width: 120,
    },
    {
      text: 'ACTIONS',
      value: 'actions',
      sortable: false,
      align: 'center',
      width: 90,
    },
  ]

  const serviceTableHeaders = () => [
    { text: 'NAME', value: 'description' },
    { text: 'COST', value: 'cost' },
    {
      text: 'ACTIONS',
      value: 'actions',
      sortable: false,
      align: 'center',
      width: 90,
    },
  ]

  const partTableHeaders = () => [
    { text: 'NAME', value: 'name' },
    { text: 'UNIT PRICE', value: 'unit_price' },
    { text: 'DESCRIPTION', value: 'description', sortable: false },
    { text: 'In Stock', value: 'in_stock' },
  ]

  const makeTableHeaders = () => [
    { text: 'NAME', value: 'name' },
    {
      text: 'ACTIONS',
      value: 'actions',
      sortable: false,
      align: 'center',
      width: 90,
    },
  ]

  const modelTableHeaders = () => [
    { text: 'MODEL', value: 'name' },
    { text: 'MAKE', value: 'make.name' },
    {
      text: 'ACTIONS',
      value: 'actions',
      sortable: false,
      align: 'center',
      width: 90,
    },
  ]

  const typeTableHeaders = () => [
    { text: 'NAME', value: 'type' },
    {
      text: 'ACTIONS',
      value: 'actions',
      sortable: false,
      align: 'center',
      width: 90,
    },
  ]

  return {
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveStockRemainingStatus,
    resolveAppointmentStatus,
    resolveUnitCondition,
    resolveDate,

    // headers
    jobOrderTableHeaders,
    preRepairTableHeaders,
    risHeaders,
    employeeTableHeaders,
    wasteReportTableHeaders,
    appointmentTableHeaders,
    vehicleTableHeaders,
    serviceTableHeaders,
    partTableHeaders,
    makeTableHeaders,
    modelTableHeaders,
    typeTableHeaders,
    materialUsedHeaders,
  }
}
